import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';


const LoadingSpinner = (props) => {
    const [show, setShow] = useState(props.display);


    useEffect(() => {
        setShow(props.display);
    }, [props]);

    return (
        <div>
            {show && < Spinner animation="grow" size="lg" ></ Spinner>}
        </div>
    );
}

export default LoadingSpinner;