

module.exports = {
    copyQuality: [
        'Dark Copies',
        'Light Copies',
        'Blank Page',
        'Background',
        'Copy Rubs Off',
        'Black Lines',
        'White Lines',
        'Spots',
        "Image Timing",
        "Image Skewed",
        "Out of Focus",
        "Other"
    ],
    electricalProblems: [
        "PM DUE",
        "No Power",
        "No Display",
        "System Locked Up",
        "C Code Error",
        "Can't Reset Mach",
        "Feeder Disabled",
        "Finisher Disabled",
        "Top Drawer Disabled",
        "Middle Drawer Disabled",
        "Lower Drawer Disabled",
        "Large Deck Disabled",
        "Bypas Disabled",
        "Duplex Disabled",
        "Stapling Problem",
        "Hole Punch Problem"
    ],
    noise: [
        "Feeder Noise",
        "Paper Feed Noise",
        "Drive Sec. Noise",
        "Finisher Noise"
    ],
    paperFeedProblems: [
        "Document Feeder Jam",
        "Misc. Jam",
        "Upper Drawer Jam",
        "Middle Drawer Jam",
        "Lower Drawer Jam",
        "Large Paper Deck Jam",
        "Bypass Jam",
        "Fuser Section Jam",
        "Exit Section Jam",
        "Duplex Section Jam",
        "Finisher Jam",
        "Stapler Jam",
        "Mailbox Jam",
        "Error Code '?'"
    ],
    printingProblems: [
        "No Print Network",
        "No Print Parellel",
        "Driver Problem",
        "Error Code ? 4"
    ],
    faxProblems: [
        "Can't Transmit",
        "Can't Receive",
        "Receives Blank",
        "Sends Black",
        "Locked Up",
        "White Line on Fax",
        "Black Line on Fax",
        "Original Feed Jam",
        "Pulls Multiple Pages",
        "Original Jam",
        "Fax Jam",
        "Noise",
        "Uneven Copy",
        "No Power",
        "Other"
    ],
    miscellaneous: [
        "Courtesy Call",
        "De-Installation",
        "Installation",
        "Key-OP Training",
        "Network Connection"
    ]
}