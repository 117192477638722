import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Modal } from 'react-bootstrap';
import axios from "axios";
import ExceptionWindow from './SubComponents/ExceptionWindow';
import LoadingSpinner from './SubComponents/LoadingSpinner';

const OrderEquipment = (props) => {

    const [inputValues, setInputValues] = useState(props.data);
    const [validated, setValidated] = useState(false);
    const [showSubmitComplete, setSubmitComplete] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showColorMeter, setShowColorMeter] = useState(false);
    const [showBWMeter, setShowBWMeter] = useState(true);
    const [deviceTypeBW, setDeviceTypeBWValue] = useState(true);
    const [deviceTypeColor, setDeviceTypeColorValue] = useState(false);
    const [showLoading, setShowLoading] = useState(false);



    const handleSubmit = (event) => {

        setShowLoading(true);
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setShowLoading(false);
            event.stopPropagation();
        }
        else {
            submitValues();
        }

        setValidated(true);
    };

    const submitValues = () => {

        const inputData = inputValues;

        let data = JSON.stringify(inputData);

        axios
            .post("/api/home/OrderEquipment", data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            ).then((response) => {
                if (response.status === 200) {
                    setSubmitComplete(true);
                }
            })
            .catch((error) => {
                displayErrorMessage(error.response.data);
                setShowLoading(false);
            });
    }

    const inputChangeHandler = event => {
        setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    };

    const deviceTypeChangeHandler = event => {

        setInputValues({ ...inputValues, "deviceType": event.target.value });

        let selectedValue = event.target.value;

        if (selectedValue === "Color") {
            setShowColorMeter(true);
            setShowBWMeter(true);
            setDeviceTypeBWValue(false);
            setDeviceTypeColorValue(true);
        }
        else {
            setShowColorMeter(false);
            setShowBWMeter(true);
            setDeviceTypeBWValue(true);
            setDeviceTypeColorValue(false);
        }
    };

    const tonerChangeHandler = event => {
        let changedValue = event.target.name;
        const checked = event.target.checked;

        let tonerValues = inputValues.toner;

        if (checked) {

            const containsValue = tonerValues.includes(changedValue);

            if (!containsValue) {

                tonerValues.push(changedValue);
            }
        }
        else {
            const containsValue = tonerValues.includes(changedValue);

            if (containsValue) {

                const index = tonerValues.indexOf(changedValue);
                if (index > -1) {
                    tonerValues.splice(index, 1);
                }
            }
        }


        setInputValues({ ...inputValues, "toner": tonerValues });
    }

    const closeSubmitModal = () => {
        setSubmitComplete(false);
        window.location.reload(false);
    }

    const displayErrorMessage = (exception) => {

        setErrorMessage(exception);
        setShowErrorMessage(true);
    }

    return (
        <div>
            <Modal show={showSubmitComplete} onHide={closeSubmitModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Your equipment submission was Successful.</Modal.Title>
                </Modal.Header>
                <Modal.Body>Submission successful!</Modal.Body>
                <Modal.Body></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeSubmitModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <ExceptionWindow display={showErrorMessage} message={errorMessage}></ExceptionWindow>
            <div className ="loadingDiv">
                <LoadingSpinner display={showLoading} />
                <LoadingSpinner display={showLoading} />
                <LoadingSpinner display={showLoading} />
                <LoadingSpinner display={showLoading} />
            </div>
            {!showLoading &&
                < Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="formFirstName">
                        <Form.Label column sm="2">First Name</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="Enter First Name" name="firstName" value={inputValues.firstName} onChange={(e) => inputChangeHandler(e)} required />
                            <Form.Control.Feedback type="invalid"> Please enter a First Name</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formLastName">
                        <Form.Label column sm="2">Last Name</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="Enter Last Name" name="lastName" value={inputValues.lastName} onChange={(e) => inputChangeHandler(e)} required />
                            <Form.Control.Feedback type="invalid"> Please enter a Last Name</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formCompany">
                        <Form.Label column sm="2">Company</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="Enter Company" name="company" value={inputValues.company} onChange={(e) => inputChangeHandler(e)} required />
                            <Form.Control.Feedback type="invalid"> Please enter a Company</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formCompanyAddress">
                        <Form.Label column sm="2">Company Address</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="Enter Company Address" name="companyAddress" value={inputValues.companyAddress} onChange={(e) => inputChangeHandler(e)} required />
                            <Form.Control.Feedback type="invalid"> Please enter a Company Address</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPhone">
                        <Form.Label column sm="2">Phone Number</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="Enter Phone Number" name="phoneNumber" value={inputValues.phoneNumber} onChange={(e) => inputChangeHandler(e)} />
                            <Form.Control.Feedback type="invalid"> Please enter a Phone Number</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formEmail">
                        <Form.Label column sm="2">Email</Form.Label>
                        <Col sm="10">
                            <Form.Control type="email" placeholder="Enter Email" name="email" value={inputValues.email} onChange={(e) => inputChangeHandler(e)} />
                            <Form.Control.Feedback type="invalid"> Please enter an Email Address</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formDeviceType">
                        <Form.Label column sm="2">Device Type</Form.Label>
                        <Col sm="10">
                            <Form.Check
                                type="radio"
                                label="B/W"
                                name="DeviceTypeRadios"
                                id="radioBW" checked={deviceTypeBW}
                                value="B/W"
                                onChange={(e) => deviceTypeChangeHandler(e)}
                            />
                            <Form.Check
                                type="radio"
                                label="Color"
                                name="DeviceTypeRadios"
                                id="radioColor" checked={deviceTypeColor}
                                value="Color"
                                onChange={(e) => deviceTypeChangeHandler(e)}
                            />
                        </Col>
                    </Form.Group>
                    {showBWMeter && <Form.Group as={Row} controlId="MeterBW">
                        <Form.Label column sm="2">B/W Meter</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="Enter BW Meter" name="meterBW" value={inputValues.meterBW} onChange={(e) => inputChangeHandler(e)} />
                            <Form.Control.Feedback type="invalid"> Please enter a B/W Meter</Form.Control.Feedback>
                        </Col>
                    </Form.Group>}
                    {showColorMeter &&
                        <Form.Group as={Row} controlId="MeterColor">
                            <Form.Label column sm="2">Color Meter</Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" placeholder="Enter Color Meter" name="meterColor" value={inputValues.meterColor} onChange={(e) => inputChangeHandler(e)} />
                                <Form.Control.Feedback type="invalid"> Please enter a Color Meter</Form.Control.Feedback>
                            </Col>
                        </Form.Group>}
                    {showColorMeter &&
                        <Form.Group as={Row} controlId="Toner">
                            <Form.Label column sm="2">Toner Needed</Form.Label>
                            <Col sm="10">
                                <Form.Check type="checkbox" label="Cyan" name="Cyan" onChange={(e) => tonerChangeHandler(e)} />
                                <Form.Check type="checkbox" label="Magenta" name="Magenta" onChange={(e) => tonerChangeHandler(e)} />
                                <Form.Check type="checkbox" label="Yellow" name="Yellow" onChange={(e) => tonerChangeHandler(e)} />
                                <Form.Check type="checkbox" label="Black" name="Black" onChange={(e) => tonerChangeHandler(e)} />
                            </Col>
                        </Form.Group>}

                    <h5>Other/Notes</h5>
                    <Form.Group as={Row} controlId="Notes">
                        <Form.Control as="textarea" rows="2" name="notes" onChange={(e) => inputChangeHandler(e)} />
                    </Form.Group>
                    <Form.Group as={Row} controlId="submitLabel">
                        <Form.Label>If ordering waste toner bottles, paper, or any other supplies, please state requested products in the "Other/Notes" box above.</Form.Label>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit Supplies
                </Button>
                </Form>
            }
        </div>
    );
}

export default OrderEquipment;