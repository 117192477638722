import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Modal } from 'react-bootstrap';
import axios from "axios";
import ExceptionWindow from './SubComponents/ExceptionWindow';
import LoadingSpinner from './SubComponents/LoadingSpinner';

const RequestService = (props) => {

    const [copyQuality, setCopyQuality] = useState(props.data.staticData.copyQuality);
    const [electricalProblems, setElectricalProblems] = useState(props.data.staticData.electricalProblems);
    const [noise, setNoise] = useState(props.data.staticData.noise);
    const [paperFeedProblems, setPaperFeedProblems] = useState(props.data.staticData.paperFeedProblems);
    const [printingProblems, setPrintingProblems] = useState(props.data.staticData.printingProblems);
    const [faxProblems, setFaxProblems] = useState(props.data.staticData.faxProblems);
    const [miscellaneous, setMiscellaneous] = useState(props.data.staticData.miscellaneous);
    const [inputValues, setInputValues] = useState(props.data);
    const [validated, setValidated] = useState(false);
    const [showSubmitComplete, setSubmitComplete] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoading, setShowLoading] = useState(false);


    useEffect(() => {
        setCopyQuality(props.data.staticData.copyQuality);
        setElectricalProblems(props.data.staticData.electricalProblems);
        setNoise(props.data.staticData.noise);
        setPaperFeedProblems(props.data.staticData.paperFeedProblems);
        setPrintingProblems(props.data.staticData.printingProblems);
        setFaxProblems(props.data.staticData.faxProblems);
        setMiscellaneous(props.data.staticData.miscellaneous);

    }, [props]);


    const handleSubmit = (event) => {
        setShowLoading(true);
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setShowLoading(false);
            event.stopPropagation();
        }
        else {
            submitValues();
        }

        setValidated(true);
    };


    const submitValues = () => {

        const inputData = inputValues;

        let data = JSON.stringify(inputData);

        axios
            .post("/api/home/RequestService", data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            ).then((response) => {
                if (response.status === 200) {
                    setSubmitComplete(true);
                }
            })
            .catch((error) => {
                displayErrorMessage(error.response.data);
                setShowLoading(false);
            });
    }

    const displayErrorMessage = (exception) => {

        setErrorMessage(exception);
        setShowErrorMessage(true);
    }


    const inputChangeHandler = event => {
        setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    };

    const contactMethodChangeHandler = event => {
        setInputValues({ ...inputValues, "contactMethod": event.target.value });
    };

    const closeSubmitModal = () => {
        setSubmitComplete(false);
        window.location.reload(false);
    }

    return (
        <div>
            <Modal show={showSubmitComplete} onHide={closeSubmitModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Your service request submission was successful.</Modal.Title>
                </Modal.Header>
                <Modal.Body>Submission successful!</Modal.Body>
                <Modal.Body></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeSubmitModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <ExceptionWindow display={showErrorMessage} message={errorMessage}></ExceptionWindow>
            <div className="loadingDiv">
                <LoadingSpinner display={showLoading} />
                <LoadingSpinner display={showLoading} />
                <LoadingSpinner display={showLoading} />
                <LoadingSpinner display={showLoading} />
            </div>
            {!showLoading &&
                <Form id="frmRequestService" noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="formFirstName">
                        <Form.Label column sm="2">First Name</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="firstName" placeholder="Enter First Name" value={inputValues.firstName} onChange={(e) => inputChangeHandler(e)} required />
                            <Form.Control.Feedback type="invalid"> Please enter a First Name</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formLastName">
                        <Form.Label column sm="2">Last Name</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="lastName" placeholder="Enter Last Name" value={inputValues.lastName} onChange={(e) => inputChangeHandler(e)} required />
                            <Form.Control.Feedback type="invalid"> Please enter a Last Name</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formCompany">
                        <Form.Label column sm="2">Company</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="company" placeholder="Enter Company" value={inputValues.company} onChange={(e) => inputChangeHandler(e)} required />
                            <Form.Control.Feedback type="invalid"> Please enter a Company</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formCompanyAddress">
                        <Form.Label column sm="2">Company Address</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="companyAddress" placeholder="Enter Company Address" value={inputValues.companyAddress} onChange={(e) => inputChangeHandler(e)} required />
                            <Form.Control.Feedback type="invalid"> Please enter a Company Address</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPhone">
                        <Form.Label column sm="2">Phone Number</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="phoneNumber" placeholder="Enter Phone Number" value={inputValues.phoneNumber} onChange={(e) => inputChangeHandler(e)} />
                            <Form.Control.Feedback type="invalid"> Please enter a Phone Number</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formEmail">
                        <Form.Label column sm="2">Email</Form.Label>
                        <Col sm="10">
                            <Form.Control type="email" name="email" placeholder="Enter Email" value={inputValues.email} onChange={(e) => inputChangeHandler(e)} />
                            <Form.Control.Feedback type="invalid"> Please enter an Email Address</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPreferedContact">
                        <Form.Label column sm="2">Preferred Contact Method</Form.Label>
                        <Col sm="10">
                            <Form.Check
                                type="radio"
                                label="Email"
                                name="ContactTypeRadios"
                                value="Email"
                                id="radioContactEmail" onChange={(e) => contactMethodChangeHandler(e)} checked={true}
                            />
                            <Form.Check
                                type="radio"
                                label="Phone"
                                name="ContactTypeRadios"
                                id="radioContactPhone"
                                value="Phone"
                                onChange={(e) => contactMethodChangeHandler(e)}
                            />
                        </Col>
                    </Form.Group>
                    <h5>Please select the Error Code that is displayed on your Impact equipment</h5>
                    <Form.Group as={Row} controlId="formCopyQuality">
                        <Form.Label column sm="2">Copy Quality</Form.Label>
                        <Col sm="10">
                            <Form.Control as="select" name="copyQuality" onChange={(e) => inputChangeHandler(e)}>
                                <option></option>
                                {copyQuality && copyQuality.map(item => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formElectricalProblem">
                        <Form.Label column sm="2">Electrical Problem</Form.Label>
                        <Col sm="10">
                            <Form.Control as="select" name='electricalProblem' onChange={(e) => inputChangeHandler(e)}>
                                <option></option>
                                {electricalProblems && electricalProblems.map(item => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formNoise">
                        <Form.Label column sm="2">Noise</Form.Label>
                        <Col sm="10">
                            <Form.Control as="select" name="noise" onChange={(e) => inputChangeHandler(e)}>
                                <option></option>
                                {noise && noise.map(item => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPaperFeedProblem">
                        <Form.Label column sm="2">Paper Feed Problem</Form.Label>
                        <Col sm="10">
                            <Form.Control as="select" name="paperFeedProblem" onChange={(e) => inputChangeHandler(e)}>
                                <option></option>
                                {paperFeedProblems && paperFeedProblems.map(item => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPrintingProblem">
                        <Form.Label column sm="2">Printing Problem</Form.Label>
                        <Col sm="10">
                            <Form.Control as="select" name='printingProblem' onChange={(e) => inputChangeHandler(e)}>
                                <option></option>
                                {printingProblems && printingProblems.map(item => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formFaxProblem">
                        <Form.Label column sm="2">Fax Problem</Form.Label>
                        <Col sm="10">
                            <Form.Control as="select" name="faxProblem" onChange={(e) => inputChangeHandler(e)}>
                                <option></option>
                                {faxProblems && faxProblems.map(item => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="Miscellaneous">
                        <Form.Label column sm="2">Miscellaneous</Form.Label>
                        <Col sm="10">
                            <Form.Control as="select" name="misc" onChange={(e) => inputChangeHandler(e)}>
                                <option></option>
                                {miscellaneous && miscellaneous.map(item => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <h5>If the issue you are experiencing is not best described by a selection above, please provide a brief description for our technicians:</h5>
                    <Form.Group as={Row} controlId="formDescription">
                        <Form.Control as="textarea" rows="2" name="description" onChange={(e) => inputChangeHandler(e)} />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit Service
                </Button>
                </Form>
            }
        </div>
    );
}

export default RequestService;