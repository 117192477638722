import React, { Component } from 'react';
import axios from "axios";
import { Row, Col, Container, Accordion, Card, Button } from 'react-bootstrap';
import RequestService from './requestservice.component';
import OrderSupplies from './orderequipment.component';


export class Home extends Component {
    constructor(props) {

        super(props);


        this.state = {
            scannedTag: "",
            requestServiceData: {
                firstName: '',
                lastName: '',
                company: '',
                companyAddress: '',
                phoneNumber: '',
                email: '',
                contactMethod: 'Email',
                description: '',
                equipmentTag: '',
                staticData: {}
            },
            orderEquipmentData: {
                firstName: '',
                lastName: '',
                company: '',
                companyAddress: '',
                phoneNumber: '',
                email: '',
                deviceType: 'B/W',
                meterBW: '',
                meterColor: '',
                toner: [],
                notes: '',
                equipmentTag: ''
            }
        }
    }

    componentDidMount() {
        this.getEquipmentData();
        const requestServiceStaticData = require('../utils/RequestServiceData');

        const requestServiceData = this.state.requestServiceData;
        requestServiceData.staticData = requestServiceStaticData;

        this.setState({ requestServiceData: requestServiceData });
    }

    componentDidUpdate(prevProps, prevState) {


        if (prevState.scannedTag !== this.state.scannedTag) {

            const tagId = this.getTagId();

            if (tagId.length > 11) {
                this.getCustomerData();
            }

            let requestService = this.state.requestServiceData;
            requestService.equipmentTag = this.state.scannedTag;

            let orderEquipment = this.state.orderEquipmentData;

            orderEquipment.equipmentTag = this.state.scannedTag;

            this.setState({ requestServiceData: requestService, orderEquipmentData: orderEquipment });
        }
    }


    getEquipmentData() {

        let tagId = this.getTagId();

        if (tagId.length > 0) {

            axios
                .get("/api/home/getequipmenttag", {
                    params: {
                        id: tagId
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;

                        this.setState({ scannedTag: data.equipmentTag });

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    getCustomerData() {
        const tagId = this.state.scannedTag;

        axios
            .get("/api/home/getcustomerdata", {
                params: {
                    equipmentnumber: tagId
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    const contactData = response.data;

                    let requestService = this.setRequestServiceContactInfo(contactData);

                    let orderEquipment = this.setOrderEquipmentContactInfo(contactData);

                    this.setState({ requestServiceData: requestService, orderEquipmentData: orderEquipment });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    setRequestServiceContactInfo(data) {

        let requestService = this.state.requestServiceData;

        requestService.firstName = data.firstName;
        requestService.lastName = data.lastName;
        requestService.company = data.company;
        requestService.companyAddress = data.companyAddress;
        requestService.phoneNumber = data.phoneNumber;
        requestService.email = data.email;
        requestService.equipmentTag = this.state.scannedTag;

        return requestService;
    }

    setOrderEquipmentContactInfo(data) {
        let orderEquipment = this.state.orderEquipmentData;

        orderEquipment.firstName = data.firstName;
        orderEquipment.lastName = data.lastName;
        orderEquipment.company = data.company;
        orderEquipment.companyAddress = data.companyAddress;
        orderEquipment.phoneNumber = data.phoneNumber;
        orderEquipment.email = data.email;
        orderEquipment.equipmentTag = this.state.scannedTag;

        return orderEquipment;
    }

    getTagId() {

        let tagId = this.props.match.params.tagid;

        if (tagId === undefined) {

            tagId = window.location.href.split("/").pop();
        }

        return tagId;
    }



    render() {

        const tagId = this.state.scannedTag;

        if (tagId !== null && tagId !== "") {
            return (
                <div>
                    <Container>
                        <Row>
                            <Col>
                                <div>
                                    Equipment Tag #{this.state.scannedTag}
                                </div>
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                Request Service
                                        </Accordion.Toggle>
                                        or call 888-752-0052
                                    </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body> <RequestService data={this.state.requestServiceData}></RequestService></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                Order Supplies
                                        </Accordion.Toggle>
                                        or call 866-228-4857
                                    </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body> <OrderSupplies data={this.state.orderEquipmentData} /></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
        else {
            return (<div></div>);
        }
    }
}
