import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

const ExceptionWindow = (props) => {
    const [show, setShow] = useState(props.display);

    const handleClose = () => setShow(false);

    useEffect(() => {
        setShow(props.display);
    }, [props]);

    return (

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={handleClose}> OK</Button>
            </Modal.Footer>
        </Modal>

    );
}

export default ExceptionWindow;